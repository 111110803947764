.aside {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--zindex-aside);
    background: hsl(var(--color-blue-lighten-4));
    border-radius: var(--border-radius);
}
